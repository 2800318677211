import "./App.css";
import Header from "./pages/Header";
import Main from "./pages/Main";
import Footer from "./pages/Footer";
import Login from "./admin/Login";
import AuthProvider from "./contexts/AuthProvider";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AdminDashboard from "./admin/AdminDashboard";
import PrivateRoute from "./admin/PrivateRoute";
import SuccessfulPayment from "./admin/SuccessfulPayment";

function App() {
  return (
    <div className="App bg-gray-200">
      <Router>
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/adminDashboard" element={<AdminDashboard />} />
            {/* <PrivateRoute
              path="/adminDashboard"
              element={<AdminDashboard />}
              exact
            /> */}
            <Route path="/login" element={<Login />} />
            <Route
              path="/payment/success/:transId"
              element={<SuccessfulPayment />}
            />
          </Routes>
          <Footer />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
