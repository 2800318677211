import React, { useState } from "react";
import InputDataType from "./InputDataType";

import InputVariable from "./InputVariable";
import InputStation from "./InputStation";
import InputResolution from "./InputResolution";
import InputStatistics from "./InputStatistics";
import DataRequired from "./DataRequired";
import InputScenario from "./InputScenario";

const AdminDashboard = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };
  return (
    <section className="w-[90%] ml-[5%] mt-[5%] gap-5 mb-5">
      <div className="flex gap-4">
        <div className="w-[20%] bg-gray-800 text-white pb-5">
          <p className="p-3 text-[18px] font-bold bg-white text-gray-800">
            Menu
          </p>
          <hr />
          <ul className="text-left pl-3 pt-3">
            <li
              className="p-3 hover:bg-white hover:text-gray-900 transition-all cursor-pointer"
              onClick={() => handleMenuItemClick("DataType")}
            >
              Data Type
            </li>

            <li
              className="p-3 hover:bg-white hover:text-gray-900 transition-all cursor-pointer"
              onClick={() => handleMenuItemClick("variable")}
            >
              Variable/Data
            </li>
            <li
              className="p-3 hover:bg-white hover:text-gray-900 transition-all cursor-pointer"
              onClick={() => handleMenuItemClick("station")}
            >
              Station
            </li>
            <li
              className="p-3 hover:bg-white hover:text-gray-900 transition-all cursor-pointer"
              onClick={() => handleMenuItemClick("datarequired")}
            >
              Data Required For
            </li>
            <li
              className="p-3 hover:bg-white hover:text-gray-900 transition-all cursor-pointer"
              onClick={() => handleMenuItemClick("resolution")}
            >
              Resolution
            </li>
            <li
              className="p-3 hover:bg-white hover:text-gray-900 transition-all cursor-pointer"
              onClick={() => handleMenuItemClick("scenario")}
            >
              Scenario
            </li>
            <li
              className="p-3 hover:bg-white hover:text-gray-900 transition-all cursor-pointer"
              onClick={() => handleMenuItemClick("statistics")}
            >
              Statistics
            </li>
          </ul>
        </div>
        <div className="w-[80%]">
          {selectedMenuItem ? (
            <>
              {selectedMenuItem === "DataType" && <InputDataType />}

              {selectedMenuItem === "variable" && <InputVariable />}
              {selectedMenuItem === "station" && <InputStation />}
              {selectedMenuItem === "resolution" && <InputResolution />}
              {selectedMenuItem === "statistics" && <InputStatistics />}
              {selectedMenuItem === "datarequired" && <DataRequired />}
              {selectedMenuItem === "scenario" && <InputScenario />}
            </>
          ) : (
            <div className="p-5">Please select an option from the menu.</div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AdminDashboard;
