import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA0fFwse6dm4qjwxVHHPvVpV0GqfBfCpLI",
  authDomain: "bmdweather-78743.firebaseapp.com",
  projectId: "bmdweather-78743",
  storageBucket: "bmdweather-78743.appspot.com",
  messagingSenderId: "120421292150",
  appId: "1:120421292150:web:81564924a7a64e5e8be757",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;
