import React, { useState, useEffect } from "react";
import { db } from "../firebaseconfig";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { collection, addDoc, getDocs } from "firebase/firestore";

const InputDataType = () => {
  const [dataList, setDataList] = useState([]);
  const [dataType, setDataType] = useState("");

  const fetchData = async () => {
    try {
      const dataCollection = collection(db, "dataTypes");
      const dataSnapshot = await getDocs(dataCollection);
      const dataList = dataSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDataList(dataList);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!dataType.trim()) {
      toast.error("You Must Enter a value!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    try {
      // Add data to Firestore collection
      const docRef = await addDoc(collection(db, "dataTypes"), {
        dataType: dataType,
      });
      fetchData();
      toast.success("Data Saved Succesfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setDataType(""); // Clear the input field after submission
    } catch (error) {
      console.log(error);
      toast.error(`Error Saving the data with error ${error}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-3  bg-gray-50 text-left p-3 w-full"
      >
        <ToastContainer />
        <label className="font-semibold">Enter a Data Type</label>
        <input
          className="p-2 border border-gray-500 rounded-lg w-[60%]"
          type="text"
          placeholder="Data Type.."
          value={dataType}
          onChange={(e) => setDataType(e.target.value)}
        />
        <button
          className="rounded-xl p-3 hover:font-bold transition-all text-center bg-blue-600 text-white w-20  "
          type="submit"
        >
          Save
        </button>
      </form>

      <div className="bg-gray-50 text-left p-3 w-full mt-4">
        <p className="font-semibold text-center underline p-3">
          List of Data Type
        </p>
        <table className="min-w-full mt-5 px-5">
          <thead>
            <tr>
              <th className="border">ID</th>
              <th className="border">Data Type</th>
            </tr>
          </thead>
          <tbody>
            {dataList &&
              dataList.map((data, index) => (
                <tr key={data.id}>
                  <td className="border">{index + 1}</td>
                  <td className="border">{data.dataType}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InputDataType;
