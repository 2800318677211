import React, { useState, useEffect } from "react";

import DatePicker from "react-datepicker";
import { getDocs, collection, addDoc } from "firebase/firestore";
import { db } from "../firebaseconfig";

import Select from "react-select";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-datepicker/dist/react-datepicker.css";
import PayNow from "../admin/PayNow";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Main = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [totalAmount, setTotalAmount] = useState(null);
  const [dataType, setDataType] = useState();
  const [dataTypeOptions, setDataTypeOptions] = useState([]);
  const [Basis, setBasis] = useState();
  const [dataRequired, setDataRequired] = useState();
  const [dataRequiredOptions, setDataRequiredOptions] = useState([]);
  const [Resolution, setResolution] = useState();
  const [ResolutionOptions, setResolutionOptions] = useState([]);
  const [Scenario, setScenario] = useState();
  const [ScenarioOptions, setScenarioOptions] = useState([]);
  const [Station, setStation] = useState([]);
  const [StationOptions, setStationOptions] = useState([]);
  const [StationLists, setStationLists] = useState([]);
  const [Statistics, setStatistics] = useState();
  const [StatisticsOptions, setStatisticsOptions] = useState([]);
  const [Variable, setVariable] = useState(null);
  const [variableOptions, setVariableOptions] = useState([]);
  const [variableLists, setVariableLists] = useState([]);

  const [Name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [Organization, setOrganization] = useState("");
  const [endTime, setEndTime] = useState();
  const [Remarks, setRemarks] = useState("");
  const [Purpose, setPurpose] = useState("");
  const [allowTerms, setAllowTerms] = useState(false);
  const [savedDataid, setSavedDataid] = useState();
  const [showPayNow, setShowPayNow] = useState(false);

  /* const CalculateDuration = (e) => {
    e.preventDefault();
    if (startDate && endTime) {
      // Calculate the difference in milliseconds between the two dates
      const timeDifference = endTime.getTime() - startDate.getTime();

      // Convert milliseconds to years (assuming 365.25 days in a year)
      const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;
      const durationInYears = timeDifference / millisecondsPerYear;

      const integerDuration = Math.floor(durationInYears);
      setTotalDuration(integerDuration);
    }
  }; */

  const handleSubmit = async (e) => {
    e.preventDefault();
    calculateTotalAmount(e, Basis, Variable, Station, startDate, endTime);
    const data = {
      Date: startDate,
      dataType: dataType,
      BasisOfData: Basis,
      DataRequired: dataRequired,
      Resolution: Resolution,
      Scenarios: Scenario,
      Stations: Station,
      Variables: Variable,
      Statistics: Statistics,
      Name: Name,
      Phone: phone,
      Email: email,
      Organization: Organization,
      EndTime: endTime,
      Remarks: Remarks,
      Purpose: Purpose,
      terms: allowTerms,
      isPaid: false,
      totalAmount: totalAmount,
    };
    const dataToSave = addDoc(collection(db, "FormData"), data).then(
      async (docRef) => {
        setSavedDataid(docRef.id);
        toast.success(
          "Your Request has been delivered. Please wait for payment option.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );

        // Send email to user

        const userEmail = email; // or whichever email you want to send to
        const emailSubject = "Confirmation Mail from BMD Portal";
        const emailHTML1 = `
          <html>
            <head>
              <style>
                /* Add your CSS styles here */
              </style>
            </head>
            <body>
              <div>
                <h3>BMD Data Portal</h3>
                <p><b>Order Details</b></p>
                <hr />
                <h5>Name: ${Name}</h5>
                <h5>Organization: ${Organization}</h5>
                <h5>Category Choosen: ${dataType}</h5>
                <h5>Purpose: ${Purpose}</h5>
                <h5>Total Amount: ${totalAmount}</h5>
                <h5>Payment Status: Pending..</h5>
                <hr/>
                <p>Your request has been received. We will contact you soon. Thank you</p>
                
              </div>
            </body>
          </html>
        `;

        try {
          const userResponse = await fetch(
            "https://weatherbmd-api.onrender.com/send-email",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                toEmail: userEmail,
                subject: emailSubject,
                html: emailHTML1,
              }),
            }
          );

          if (userResponse.ok) {
            console.log("Email sent to user successfully.");
          } else {
            console.error("Error sending email to user.");
          }
        } catch (error) {
          console.error("Error:", error);
        }

        // Send email to admin

        const emailHTML2 = `
          <html>
            <head>
              <style>
                /* Add your CSS styles here */
              </style>
            </head>
            <body>
              <div>
                <h3>BMD Data Portal</h3>
                <hr />
                <h5>Name: ${Name}</h5>
                <h5>Phone: ${phone}</h5>
                <h5>Email: ${email}</h5>
                <h5>Organization: ${Organization}</h5>
                <h5>Category Choosen: ${dataType}</h5>
                <h5>Purpose: ${Purpose}</h5>
                <h5>Total Amount: ${totalAmount}</h5>
                <h5>Payment Status: Pending..</h5>
                <p>Auto Generate Mail From BMD DATA PORTAL</p>
                
              </div>
            </body>
          </html>
        `;
        const adminResponse = await fetch(
          "https://weatherbmd-api.onrender.com/send-email",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              toEmail: "climate1971@gmail.com",
              subject: emailSubject,
              html: emailHTML2,
            }),
          }
        );

        if (adminResponse.ok) {
          console.log("Email sent to admin successfully.");
        } else {
          console.error("Error sending email to admin.");
        }

        setBasis("");
        setName("");
        setEmail("");
        setOrganization("");
        setPhone("");
        setRemarks("");
        setPurpose("");
        setVariable("");
        setResolution("");
        setStation("");
        setDataRequired("");
        setDataType("");
        setScenario("");
        setStatistics("");
        setStartDate("");
        setEndTime("");
        setAllowTerms("");
        setShowPayNow(true);
      }
    );
  };

  const calculateTotalAmount = (e, B, V, S, sD, eD) => {
    e.preventDefault();
    const vSize = V.length;
    const sSize = S.length;
    let tA = 0;
    let vat = 0;
    let yearCount;

    const timeDifference = eD.getTime() - sD.getTime();

    const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;
    const durationInYears = timeDifference / millisecondsPerYear;

    const integerDuration = Math.floor(durationInYears);

    if (integerDuration >= 1 && integerDuration <= 5) {
      yearCount = Math.ceil(integerDuration / 5); // Divide by 5 and round up
    } else {
      yearCount = Math.floor(integerDuration / 5); // Divide by 5 and round down
    }

    yearCount = Math.max(1, yearCount);

    if (B === "3 Hour") {
      tA = 1000 * vSize + sSize * 200 + 200 * yearCount;
      vat = tA * 0.15;

      tA = tA + vat;
      setTotalAmount(tA);
    } else if (B === "Daily") {
      tA = 800 * vSize + sSize * 100 + 100 * yearCount;
      vat = tA * 0.15;

      tA = tA + vat;
      setTotalAmount(tA);
    } else if (B === "Monthly") {
      tA = 100 * vSize + sSize * 100 + 100 * yearCount;
      vat = tA * 0.15;
      tA = tA + vat;
      setTotalAmount(tA);
    }
  };

  useEffect(() => {
    const fetchDataType = async () => {
      try {
        const datatypeSnapshot = await getDocs(collection(db, "dataTypes"));
        setDataTypeOptions(datatypeSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching data options:", error);
      }
    };

    const fetchStations = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "Station"));

        setStationOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    const fetchDataRequired = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "Datarequired"));
        setDataRequiredOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    const fetchResolutions = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "Resolution"));
        setResolutionOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    const fetchScenarios = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "Scenario"));
        setScenarioOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    const fetchStatistics = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "Statistics"));
        setStatisticsOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    const fetchVariables = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "variable"));

        setVariableOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    fetchDataType();
    fetchStations();
    fetchResolutions();
    fetchDataRequired();
    fetchStations();
    fetchStatistics();
    fetchVariables();
    fetchScenarios();
  }, []);
  const convertToOptions = (firebaseData) => {
    return firebaseData.map((item) => ({
      value: item.dataType.toLowerCase().replace(" ", "_"),
      label: item.dataType,
    }));
  };
  useEffect(() => {
    setVariableLists(convertToOptions(variableOptions));
  }, [variableOptions]);

  useEffect(() => {
    setStationLists(convertToOptions(StationOptions));
  }, [StationOptions]);

  return (
    <section className="w-[90%] ml-[5%] mt-[5%] flex gap-5 mb-5">
      <ToastContainer />
      <div className="w-[50%]">
        <div className="flex justify-between">
          <p className="text-[16px] font-bold text-[#c21ec2]">
            Climate and Weather Data Purchase
          </p>
          <p className="text-[16px] font-bold text-[#c21ec2] pr-[7%]">
            Visitor: <span>42000</span>
          </p>
        </div>
        <form className="">
          <div className="flex gap-5">
            <div className="w-[50%] text-left pt-3">
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Customer Name<span className="text-red-600 font-bold">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Customer Name"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                />
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Mobile Number<span className="text-red-600 font-bold">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                />
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Data Type<span className="text-red-600 font-bold">*</span>
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  onChange={(e) => setDataType(e.target.value)}
                  value={dataType}
                >
                  <option>Select..</option>
                  {dataTypeOptions &&
                    dataTypeOptions.map((item, index) => (
                      <option key={index}>{item.dataType}</option>
                    ))}
                </select>
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Time Period (From)
                  <span className="text-red-600 font-bold">*</span>
                </label>
                <DatePicker
                  className="appearance-none block border border-gray-600 rounded-xl p-2 w-[295px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>
            <div className="w-[50%] text-left pt-3">
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Office/Organization
                </label>
                <input
                  type="text"
                  placeholder="Office/Organization"
                  value={Organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                />
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Email Address<span className="text-red-600 font-bold">*</span>
                </label>
                <input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                />
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Basis Of Data<span className="text-red-600 font-bold">*</span>
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  value={Basis}
                  onChange={(e) => setBasis(e.target.value)}
                  disabled={!dataType}
                >
                  <option aria-readonly>Select...</option>
                  <option>3 Hour</option>
                  <option>Daily</option>
                  <option>Monthly</option>
                </select>
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Time Period (To)
                  <span className="text-red-600 font-bold">*</span>
                </label>
                <DatePicker
                  className="appearance-none block border border-gray-600 rounded-xl p-2 w-[295px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  selected={endTime}
                  onChange={(date) => setEndTime(date)}
                />
              </div>
            </div>
          </div>
          <p className="text-left text-[16px] font-bold text-[#c21ec2]">
            Note: Apply only for 2 months before data from running month
          </p>
          <div className="w-[98%] pb-5 text-left pt-3">
            <label htmlFor="" className="font-semibold">
              Variable/Data<span className="text-red-600 font-bold">*</span>
            </label>
            <Select
              options={variableLists}
              value={Variable}
              defaultValue={null}
              onChange={(op) => setVariable(op)}
              isMulti={true}
            />
          </div>
          <div className="w-[98%] pb-5 text-left pt-3">
            <label htmlFor="" className="font-semibold">
              Station<span className="text-red-600 font-bold">*</span>
            </label>

            <Select
              options={StationLists}
              value={Station}
              defaultValue={null}
              onChange={(op) => setStation(op)}
              isMulti={true}
            />
          </div>
          <div className="flex justify-end">
            {totalAmount && (
              <p className="p-2 font-semibold">
                Fees: {totalAmount}. (15% VAT/TAX Included)
              </p>
            )}
            <button
              className="p-3 rounded-lg bg-blue-500 text-white font-semibold text-right"
              onClick={(e) =>
                calculateTotalAmount(
                  e,
                  Basis,
                  Variable,
                  Station,
                  startDate,
                  endTime
                )
              }
            >
              Calculate Price
            </button>
          </div>
        </form>
        <form>
          <div className="w-[98%] pb-5 pt-3 text-left">
            <label htmlFor="" className="font-semibold">
              Remarks
            </label>
            <input
              type="text"
              placeholder="Remarks"
              value={Remarks}
              onChange={(e) => setRemarks(e.target.value)}
              className="border border-gray-600 rounded-xl p-2 w-[100%]"
            />
          </div>

          <div className="flex gap-5">
            <div className="w-[50%] text-left pt-3">
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Data Required For
                  <span className="text-red-600 font-bold">*</span>
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  onChange={(e) => setDataRequired(e.target.value)}
                  value={dataRequired}
                >
                  <option>Select Reason...</option>
                  {dataRequiredOptions &&
                    dataRequiredOptions.map((item, index) => (
                      <option key={index}>{item.dataType}</option>
                    ))}
                </select>
              </div>

              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Scenario
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  onChange={(e) => setScenario(e.target.value)}
                  value={Scenario}
                >
                  <option>Select Scenario...</option>
                  {ScenarioOptions &&
                    ScenarioOptions.map((item, index) => (
                      <option key={index}>{item.dataType}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="w-[50%] text-left pt-3">
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Resolution
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  onChange={(e) => setResolution(e.target.value)}
                  value={Resolution}
                >
                  <option>Select Resolution...</option>
                  {ResolutionOptions &&
                    ResolutionOptions.map((item, index) => (
                      <option key={index}>{item.dataType}</option>
                    ))}
                </select>
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Statistics
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  onChange={(e) => setStatistics(e.target.value)}
                  value={Statistics}
                >
                  <option>Select Statistics...</option>
                  {StatisticsOptions &&
                    StatisticsOptions.map((item, index) => (
                      <option key={index}>{item.dataType}</option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="w-[98%] pb-5 pt-3 text-left">
            <label htmlFor="" className="font-semibold">
              Specify Purpose
            </label>
            <input
              type="text"
              placeholder="Specify Purpose"
              value={Purpose}
              onChange={(e) => setPurpose(e.target.value)}
              className="border border-gray-600 rounded-xl p-2 w-[100%]"
            />
          </div>

          <div className="flex justify-between">
            <div className="flex">
              <input
                id="default-checkbox"
                type="checkbox"
                checked={allowTerms}
                onChange={() => setAllowTerms(true)}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="default-checkbox "
                class="ml-2 text-blue-600 font-semibold text-[14px]"
              >
                Terms and Conditions
                <span className="text-red-600 font-bold">*</span>
              </label>
            </div>

            <button
              className="p-2 rounded-lg bg-blue-500 text-white text-[14px] font-semibold text-right"
              onClick={handleSubmit}
              disabled={!allowTerms}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="w-[50%]">
        <div className="flex justify-between pb-3">
          <p className="text-[16px] font-bold text-[#c21ec2] pl-[5%]">
            GoB Approved Rate of Climate and Weather Data
          </p>
          <p className="text-[14px] font-bold text-white p-2 bg-blue-500 rounded-xl cursor-pointer">
            Pay Now
          </p>
        </div>
        <table className="w-[100%] table pt-3 pb-3">
          <thead>
            <tr className="">
              <th className="bg-gray-400 border-gray-700 border p-1">S.N.</th>
              <th className="bg-gray-400 border-gray-700 border p-1">Item</th>
              <th className="bg-gray-400 border-gray-700 border p-1">Number</th>
              <th className="bg-gray-400 border-gray-700 border p-1">
                3-Hour Data
              </th>
              <th className="bg-gray-400 border-gray-700 border p-1">Daily</th>
              <th className="bg-gray-400 border-gray-700 border p-1">
                Monthly
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-700 p-1">1</td>
              <td className="border border-gray-700 p-1">Any Item</td>
              <td className="border border-gray-700 p-1">1</td>
              <td className="border border-gray-700 p-1">Tk 1000.00</td>
              <td className="border border-gray-700 p-1">Tk 800.00</td>
              <td className="border border-gray-700 p-1">Tk 100.00</td>
            </tr>
            <tr>
              <td className="border border-gray-700 p-1">2</td>
              <td className="border border-gray-700 p-1">Number of Stations</td>
              <td className="border border-gray-700 p-1">1</td>
              <td className="border border-gray-700 p-1">Tk 200.00</td>
              <td className="border border-gray-700 p-1">Tk 100.00</td>
              <td className="border border-gray-700 p-1">Tk 100.00</td>
            </tr>
            <tr>
              <td className="border border-gray-700 p-1">3</td>
              <td className="border border-gray-700 p-1">Time</td>
              <td className="border border-gray-700 p-1">Evry 5 years</td>
              <td className="border border-gray-700 p-1">Tk 200.00</td>
              <td className="border border-gray-700 p-1">Tk 100.00</td>
              <td className="border border-gray-700 p-1">Tk 100.00</td>
            </tr>
          </tbody>
        </table>
        <p className="pt-3 font-semibold text-left text-[16px]">
          Note: Charge and 15%VAT/TAX will be Payable
        </p>
        <p className="text-[16px] font-bold text-[#c21ec2] pt-3 text-left">
          Transaction Gateways
        </p>
        <table className="w-[100%] table pt-3 pb-3">
          <thead>
            <tr>
              <th className="bg-gray-400 border-gray-700 border p-1">Method</th>
              <th className="bg-gray-400 border-gray-700 border p-1">Charge</th>
              <th className="bg-gray-400 border-gray-700 border p-1">Method</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-700 p-1  pl-[5%]">
                <img className="w-28" src="images/VISA.png" />
              </td>
              <td className="border border-gray-700 p-1 pl-[5%]">
                <img className="w-28" src="images/mastercard.png" />
              </td>
              <td className="border border-gray-700 p-1 pl-[5%]">
                <img className="w-28" src="images/aex.png" />
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-[16px] font-bold text-[#c21ec2] pt-3 text-left">
          Help Line
        </p>
        <table className="w-[100%] text-left pt-3 pb-3">
          <thead>
            <tr>
              <th className="bg-gray-400 border p-3">
                Ranjan Kumar Kundu:{" "}
                <span className="font-normal">+8801915657307</span>
              </th>
            </tr>
            <tr>
              <th className="bg-gray-50 border p-3">
                Akram Hossain:{" "}
                <span className="font-normal">+8801680274628</span>
              </th>
            </tr>
            <tr>
              <th className="bg-gray-400 border p-3">
                Mir Farida Shahen:{" "}
                <span className="font-normal">+8801552314132</span>
              </th>
            </tr>
            <tr>
              <th className="bg-gray-50 border p-3">
                Emergency Contact:{" "}
                <span className="font-normal">+88-02-48110967</span>
              </th>
            </tr>
            <tr>
              <th className="bg-gray-400 border p-3">
                Email:{" "}
                <span className="font-normal">
                  climate_bmd@yahoo.com & info@bmd.gov.bd
                </span>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <Modal
        isOpen={showPayNow}
        style={customStyles}
        contentLabel="BMD Data Portal"
      >
        <div className="flex justify-between p-2">
          <p className="pt-2 font-semibold text-[16px] ">BMD Data Portal</p>
          <button
            className="border border-gray-500 rounded-xl p-2"
            onClick={() => setShowPayNow(false)}
          >
            close
          </button>
        </div>
        <hr />
        <PayNow amount={totalAmount} dataid={savedDataid} />
      </Modal>
    </section>
  );
};

export default Main;
