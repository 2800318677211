import React from "react";

import { doc, getDoc } from "firebase/firestore";

import { db } from "../firebaseconfig";

const PayNow = ({ amount, dataid }) => {
  const handlePayment = async () => {
    const dataRef = doc(db, "FormData", dataid);
    const docSnap = await getDoc(dataRef);
    const data = docSnap.data();

    const requestData = {
      dataid: dataid,
      data: data,
    };
    fetch("https://weatherbmd-api.onrender.com/pay-now", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((result) => {
        window.location.replace(result.url);
      });
  };
  return (
    <section className="p-3 m-3">
      <div>
        <p className="text-[14px] p-1">
          Your request have been submitted. Pay now to confirm your purchase.
        </p>
        <p className="text-[14px] p-1">
          Your Total Payable Amount:{" "}
          <span className="font-semibold">{amount} BDT</span>
        </p>
        <button
          onClick={handlePayment}
          className="rounded-xl p-2 bg-blue-600 text-white font-semibold text-[14px] mt-2"
        >
          Pay Now
        </button>
      </div>
    </section>
  );
};

export default PayNow;
