import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, updateDoc, getDoc } from "firebase/firestore";

import { db } from "../firebaseconfig";
const SuccessfulPayment = () => {
  const navigate = useNavigate();
  const [documentData, setDocumentData] = useState(null);
  const id = useParams();
  const docId = id.transId;
  console.log(id.transId);

  useEffect(() => {
    // Update the Firestore document when the component mounts
    const docRef = doc(db, "FormData", docId); // Assuming 'FormData' is your collection name
    // Update the 'isPaid' field to true
    updateDoc(docRef, {
      isPaid: true,
    })
      .then(async () => {
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          setDocumentData(docSnapshot.data());

          // Send email to user

          const userEmail = documentData && documentData.Email; // or whichever email you want to send to
          const emailSubject = "Payment Confirmation Mail from BMD Portal";
          const emailHTML1 = `
          <html>
            <head>
              <style>
                /* Add your CSS styles here */
              </style>
            </head>
            <body>
              <div>
                <h3>BMD Data Portal</h3>
                <p><b>Payment Details</b></p>
                <hr />
                <h5>Name: ${documentData && documentData.Name}</h5>
                <h5>Organization: ${
                  documentData && documentData.Organization
                }</h5>
                <h5>Category Choosen: ${
                  documentData && documentData.dataType
                }</h5>
                
                <h5>Total Amount: ${
                  documentData && documentData.totalAmount
                }</h5>
                <h5>Payment Status: <span color="Green">Paid</span></h5>
                <hr/>
                <p>Thank you for being with us</p>
                
              </div>
            </body>
          </html>
        `;

          try {
            const userResponse = await fetch(
              "https://weatherbmd-api.onrender.com/send-email",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  toEmail: userEmail,
                  subject: emailSubject,
                  html: emailHTML1,
                }),
              }
            );

            if (userResponse.ok) {
              console.log("Email sent to user successfully.");
            } else {
              console.error("Error sending email to user.");
            }
          } catch (error) {
            console.error("Error:", error);
          }

          // Send email to admin

          const emailHTML2 = `
          <html>
            <head>
              <style>
                /* Add your CSS styles here */
              </style>
            </head>
            <body>
              <div>
                <h3>BMD Data Portal - User Payment Log</h3>
                <hr />
                <h5>Name: ${documentData && documentData.Name}</h5>
                <h5>Phone: ${documentData && documentData.phone}</h5>
                <h5>Email: ${documentData && documentData.Email}</h5>
                <h5>Organization: ${
                  documentData && documentData.Organization
                }</h5>
                <h5>Category Choosen: ${
                  documentData && documentData.dataType
                }</h5>
                
                <h5>Total Amount: ${
                  documentData && documentData.totalAmount
                }</h5>
                <h5>Payment Status: <span color="Green">Paid</span> </h5>
                <p>Auto Generate Mail From BMD DATA PORTAL</p>
                
              </div>
            </body>
          </html>
        `;
          const adminResponse = await fetch(
            "https://weatherbmd-api.onrender.com/send-email",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                toEmail: "climate1971@gmail.com",
                subject: emailSubject,
                html: emailHTML2,
              }),
            }
          );

          if (adminResponse.ok) {
            console.log("Email sent to admin successfully.");
          } else {
            console.error("Error sending email to admin.");
          }
        }
      })
      .catch((error) => {
        console.error("Error updating document:", error);
      });
  }, [docId]);
  return (
    <section className="w-[90%] ml-[5%] mt-[5%] flex gap-5 mb-5">
      {documentData ? (
        <>
          <p className="text-green-700 font-semibold text-14">
            Payment Successfull. An Email has been Sent!
          </p>
          <button
            className="p-2 rounded-xl bg-blue-600 text-white font-semibold"
            onClick={() => navigate("/")}
          >
            Go to Homepage
          </button>
        </>
      ) : (
        <>
          <p>Loading....</p>
        </>
      )}
    </section>
  );
};

export default SuccessfulPayment;
