import React from "react";

const Footer = () => {
  return (
    <section className="pt-2 pb-2 ">
      <hr className="border-1 border-gray-900 " />
      <p className="font-semibold text-[14px] text-left pl-10">
        <span className="text-gray-900">@ Copyright 2023. </span>{" "}
        <span className="text-gray-900">
          Design, Develop, Hosted & Maintained By:{" "}
          <a className="text-blue-600" href="https://www.websoftbd.net">
            WEB SOFT BD
          </a>
        </span>{" "}
      </p>
    </section>
  );
};

export default Footer;
